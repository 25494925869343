// material-ui
import { useTheme } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project imports
import { gridSpacing } from 'store/constant';
import { ThemeMode } from 'config';
import { appDrawerWidth } from 'utils/constantString';

// ==============================|| NO/EMPTY MAIL ||============================== //

const MailEmpty = ({imgDark,  message1 , message2}) => {
    const theme = useTheme();

    return (
        <Stack  sx={{ 
            display:'flex',
            justifyContent:'center',
            alignItems: 'center',
            textAlign:'center',
            width:'100%',
             padding:"80px",
        }}  spacing={2}>
            <CardMedia
                component="img"
                image={imgDark}
                title="Slider5 image"
                sx={{ width: 300,margin:'0 auto'}}
            />
        
            <Stack spacing={1}>
                <Typography variant="h2" color="inherit">
                    {message1}
                </Typography>
                <Typography variant="body2">
                    {message2}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default MailEmpty;

import { amber, blue, indigo, teal } from "@mui/material/colors";
import { genderProps } from "../../utils/types/dataTypes";
import dayjs from "dayjs";
import { getFormattedDate, getFormattedDateTime } from "../../utils/general";

export const status = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Upcoming",
    value: "pending",
  },
  {
    label: "Cancelled",
    value: "canceled",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Client Rejected",
    value: "clientrejected",
  },
  {
    label: "No Show",
    value: "noshow",
  },
];

export const opeiningTime = 0;
export const closingTime = 24;

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Prefer Not to Say", value: "prefer not to say" },
];

export const readyforAppointmentsOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

export const AddStaffstatusOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false }
];
export const productServiceTypeOptions = [
  { label: "Service", value: "service" },
  { label: "Product", value: "product" },
  { label: "Packages", value: "package" },
];

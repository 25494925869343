// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';
import { ENDPOINTS } from 'network/ENDPOINT';
import { customerRegister } from 'utils/types/requestType';
import { Users } from 'utils/types/responseType';
import authSlice from 'store';
import { openSnackbar } from 'store/snackbar/slice';
// ----------------------------------------------------------------------

const initialState = {
    error: null,
    customers: [],
    customer: null,
    orders: [],
    products: [],
    productreviews: [],
    invoices: [],
    loading:false,
    isEditing:false,
    isApiFulfilled:true
};

const slice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CUSTOMERS
        getCustomersSuccess(state, action) {
            state.customers = action.payload;
        },

        // GET ORDERS
        getOrdersSuccess(state, action) {
            state.orders = action.payload;
        },

        // GET PRODUCTS
        getProductsSuccess(state, action) {
            state.products = action.payload;
        },

        // GET PRODUCT REVIEWS
        getProductReviewsSuccess(state, action) {
            state.productreviews = action.payload;
        },

       
        

        // ADD CUSTOMER
        addCustomerSuccess(state, action) {
            state.customers = [...state.customers,action.payload];
            state.customer =  action.payload;
            state.isApiFulfilled = true;
        },

           // UPDATE CUSTOMER
           updateCustomerSuccess(state, action) {
            const newCustomerList = state.customers?.map((item, key) => {
                if (action.payload?.id == item?.id) {
                    return { ...action.payload};
                }
                return item;
            });
            state.customers = newCustomerList;
            state.customer =  action.payload;
            state.isApiFulfilled = true;
           },
        // START CUSTOMER
        setCustomerSuccess(state, action){
            state.customer = action.payload;
        },

        setEditingSuccess(state,action){
            
                state.isEditing=action.payload
        },
        setAPICallStatus(state,action){
            state.isApiFulfilled=action.payload;
        }

        
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomers({companyId,paginationUrl}) {
    return async () => {
        try {
            const response = await axios.get(`${ENDPOINTS.USER_API}?companyId=${companyId}&role=client${paginationUrl ? paginationUrl : ""}`);
            dispatch(slice.actions.getCustomersSuccess(response.data.data.users));
            //dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            //dispatch(slice.actions.setLoading(false));
        }
    };
}

export function setCustomer(user){
    return async () => {
        try {
            dispatch(slice.actions.setCustomerSuccess(user));
        }catch(error){
            dispatch(slice.actions.hasError(error));
        }

    }
    //dispatch(slice.actions.setCustomerSuccess(user));
}

export function getOrders() {
    return async () => {
        try {
            const response = await axios.get('https://mock-data-api-nextjs.vercel.app/api/customer/order/list');
            dispatch(slice.actions.getOrdersSuccess(response.data.orders));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// export function getInvoice() {
//     return async () => {
//         try {
//             const response = await axios.get('/api/invoice/list');
//             dispatch(slice.actions.getInvoiceSuccess(response.data.invoice));
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function getProducts() {
    return async () => {
        try {
            const response = await axios.get('/api/products/list');
            dispatch(slice.actions.getProductsSuccess(response.data.products));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProductReviews() {
    return async () => {
        try {
            const response = await axios.get('/api/customer/product/reviews');
            dispatch(slice.actions.getProductReviewsSuccess(response.data.productreviews));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addCustomer({id, payload,handleSelectClient }) {
    return async () => {
        try {
            dispatch(slice.actions.setAPICallStatus(false));
            const response = await axios.post(`${ENDPOINTS.ADD_CUSTOMER_API}/${id}`,payload);
        
                //console.log("response.data.data" , response.data.data)
                //dispatch(slice.actions.setLoading(false));
                handleSelectClient && handleSelectClient(response.data.data)
                dispatch(slice.actions.addCustomerSuccess(response.data.data));
                dispatch(slice.actions.hasError(false));
                dispatch(openSnackbar({
                    open: true,
                    message: 'The new customer has been added successfully.',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                    close: true
                }))
           
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            //dispatch(slice.actions.setLoading(false));
        }
    };
}

export function updateCustomer({id, payload}) {
    return async () => {
        try {
            dispatch(slice.actions.setAPICallStatus(false));
            const response = await axios.post(`${ENDPOINTS.ADD_CUSTOMER_API}/${id}`,payload);
        
                //console.log("response.data.data" , response.data.data)
                //dispatch(slice.actions.setLoading(false));
                dispatch(slice.actions.updateCustomerSuccess(response.data.data));
                dispatch(slice.actions.setEditingSuccess(false));
                dispatch(slice.actions.hasError(false));
                dispatch(openSnackbar({
                    open: true,
                    message: 'The selected customer has been updated successfully.',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                    close: true
                }))
           
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(slice.actions.setEditingSuccess(true));
            dispatch(openSnackbar({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            //dispatch(slice.actions.setLoading(false));
        }
    };
}


export function setEditing ({isEditing}){
    return async () => {
        try {
            dispatch(slice.actions.setEditingSuccess(isEditing));
        }catch(error){
            dispatch(slice.actions.hasError(error));
        }
    }
}
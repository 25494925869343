// third-party
import { createSlice , current } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';
import { ENDPOINTS } from 'network/ENDPOINT';
import { customerRegister } from 'utils/types/requestType';
import { Users } from 'utils/types/responseType';
import { NavigateFunction } from 'react-router-dom';
import { openSnackbar } from 'store/snackbar/slice';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    invoices: [],
};

const slice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {

         // HAS ERROR
         hasError(state, action) {
            state.error = action.payload;
        },
     
        // GET INVOICE DATA
        getInvoiceSuccess(state, action) {
            state.invoices = action.payload;
        },

         //ADD INVOICE DATA
        addInvoiceSuccess(state:any, action) {
            state.invoices = [action.payload, ...state.invoices];
        },

        // UPDATE INVOICE
        updateInvoiceList(state:any, action) {
            const temp = state.invoices?.map((item:any)=>{
                if(item?.id === action.payload){
                    return action.payload;
                }
                return item;
            }) 
            state.invoices = temp;
        },

        // DELETE INVOICE
        removeInvoiceList(state:any, action) {
            const temp = state.invoices?.filter((i:any)=> i?.id !== action.payload);
            state.invoices = temp;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInvoiceData({companyId , search}:{companyId:string , search:string}) {
    return async () => {
        try {
            let response;
            if(search)
                response = await axios.get(`${ENDPOINTS.GET_INVOICE_LIST}?companyId=${companyId}&search=${search}`);
            else
                response = await axios.get(`${ENDPOINTS.GET_INVOICE_LIST}?companyId=${companyId}`);

            if(response?.data?.success){
                dispatch(slice.actions.getInvoiceSuccess(response.data.data.records));
            }else{
            }
        } catch (error) {
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoiceOne({id}:{id:string}) {
    return async () => {
        try {
            let response= await axios.get(`${ENDPOINTS.GET_INVOICE}/${id}`);
            if(response?.data?.success){
                dispatch(slice.actions.getInvoiceSuccess(response.data.data.records));
            }else{
            }
        } catch (error) {
             dispatch(slice.actions.hasError(error));
        }
    };
}


export function addInvoice({payload}:{payload:any}) {
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.ADD_INVOICE,payload);
            if(response?.data?.success){
                dispatch(slice.actions.addInvoiceSuccess(response.data.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Submit Success',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        close: false
                    })
                );
                return response;
            }
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            return error

        }
    };
}

export function deleteInvoice({id}:{id:string}) {
    return async () => {
        try {
           const response = await axios.delete(`${ENDPOINTS.DELETE_INVOICE}/${id}`);
            if(response?.data?.success){
                dispatch(slice.actions.removeInvoiceList(id));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response?.data?.message,
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        close: false
                    })
                );
            }
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function getInvoiceSingleData({companyId}:{companyId:string}) {
    return async () => {
        try {
            const response = await axios.get(`${ENDPOINTS.GET_INVOICE_LIST}?companyId=${companyId}`);
            if(response?.data?.success){
                dispatch(slice.actions.getInvoiceSuccess(response.data.data.records));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editInvoice({payload}:{payload:any}) {
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.ADD_INVOICE,payload);
            if(response?.data?.success){
                dispatch(slice.actions.updateInvoiceList(response?.data?.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response?.data?.message,
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        close: false
                    })
                );
                return response;
            }else{
            }
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            return error
        }
    };
}
import React from "react";
import { useAppSelector } from "store";

export const useCompanyData = () => {
  const {ownerData} = useAppSelector(state=>state.authentication);
  //const current = useAppSelector(selectCurrentOutlet);

  let primaryBusinessLocation = ownerData?.ownerBusiness?.businessLocations?.find((i)=> {return i?.isPrimaryCompany == true});
  //console.log("primaryBusinessLocation" , primaryBusinessLocation)
  let companyId = primaryBusinessLocation?.id
  
  /*if(current){
    companyId = current?.id
  }else{
    companyId = ownerData?.ownerBusiness?.businessLocations?.find((i)=> {return i?.isPrimaryCompany == true})?.id;
  }*/
  return companyId;
};

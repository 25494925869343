// material-ui
import Grid from '@mui/material/Grid';

// project imports
import SkeletonTotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import { gridSpacing } from 'store/constant';
import MainCard from '../MainCard';
import SubCard from '../SubCard';
import { Card, List, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';

// ==============================|| CATALOGUE SKELETON ||============================== //

const CatalogueSkeleton = () => (
        <MainCard >
                <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={12}>
                                <Card sx={{ p: 2 }}>
                                        <List sx={{ py: 0 }}>
                                                <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                                        <ListItemAvatar>
                                                                <Skeleton variant="rectangular" width={44} height={44} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                                sx={{ py: 0 }}
                                                                primary={<Skeleton variant="rectangular" height={20} />}
                                                                secondary={<Skeleton variant="text" />}
                                                        />
                                                </ListItem>
                                                <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                                        <ListItemAvatar>
                                                                <Skeleton variant="rectangular" width={44} height={44} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                                sx={{ py: 0 }}
                                                                primary={<Skeleton variant="rectangular" height={20} />}
                                                                secondary={<Skeleton variant="text" />}
                                                        />
                                                </ListItem>
                                                <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                                        <ListItemAvatar>
                                                                <Skeleton variant="rectangular" width={44} height={44} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                                sx={{ py: 0 }}
                                                                primary={<Skeleton variant="rectangular" height={20} />}
                                                                secondary={<Skeleton variant="text" />}
                                                        />
                                                </ListItem>
                                                <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                                        <ListItemAvatar>
                                                                <Skeleton variant="rectangular" width={44} height={44} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                                sx={{ py: 0 }}
                                                                primary={<Skeleton variant="rectangular" height={20} />}
                                                                secondary={<Skeleton variant="text" />}
                                                        />
                                                </ListItem>
                                                <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                                        <ListItemAvatar>
                                                                <Skeleton variant="rectangular" width={44} height={44} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                                sx={{ py: 0 }}
                                                                primary={<Skeleton variant="rectangular" height={20} />}
                                                                secondary={<Skeleton variant="text" />}
                                                        />
                                                </ListItem>
                                        </List>
                                </Card>
                        </Grid>
                </Grid>
        </MainCard>
);

export default CatalogueSkeleton;

export const apiSignature = {
    AUTH_LOGIN : "auth/loginAPI",
    AUTH_REGISTER : "auth/fetchSignupAPI",
    AUTH_EMAIL_VERIFY : "auth/emailVerification",
    AUTH_LOGOUT:"auth/logout",
    UPDATE_PASSWORD:"auth/updatePasswordAPI",
    AUTH_FORGOT_PASSWORD:"auth/forgotPasswordAPI",
    AUTH_RESET_PASSWORD:"auth/resetPasswordAPI",
    AUTH_VERIFY_OTP:"auth/verifyOTP",
    
}
import { RouterProvider } from 'react-router-dom';

// routing
import router from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Notistack from 'ui-component/third-party/Notistack';

import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { dispatch, useAppSelector as useSelector } from 'store';
import { useEffect, useState } from 'react';
import { useCompanyData } from 'components/hooks/useCompanyData';
import { getCategory } from 'store/catalogue/slice';
import { getStaffs } from 'store/employees/slice';
import dayjs from 'dayjs';
import { getEvents } from 'store/calendar/slice';
import { getCustomers } from 'store/customer/slice';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const AppointimizeApp = () => {
    const companyId = useCompanyData();
    const authState = useSelector(state=>state.authentication)
    const token = authState.token;//data?.auth?.token;
    useEffect (()=>{
        if(token){
            const from = dayjs ().startOf('month').format('YYYY-MM-DD');
            const end = dayjs().endOf('month').format('YYYY-MM-DD');
            dispatch (getCategory(companyId)).then(()=>{
                //setLoading(false);
            });
            dispatch (getStaffs(companyId)).then(()=>{
                //setLoading(false);
            });

            dispatch(
                getEvents (
                    {   companyId,
                        appointmentDateRange:`[${from},${end}]`,
                        limit:500
                    })
                )
            dispatch(getCustomers ({companyId}));
        }
       
    },[])
    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Notistack>
                                    <RouterProvider router={router} />
                                    <Snackbar />
                                </Notistack>
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default AppointimizeApp;

import React from 'react'
import { Button, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, MenuItem, Select, Stack, Switch, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'store';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import { productServiceTypeOptions } from 'components/custom-scheduler/data';
import { useCompanyData } from 'components/hooks/useCompanyData';
import { v4 as uuid } from 'uuid'

const getInitialValues = (catType, selectedData) => {
    const initialValues = {
        category_name: selectedData?.title ?? "",
        categoryEnableForAppointment: selectedData?.status == "inactive" ? false : true,
        type: selectedData?.cateType ? selectedData?.cateType : (catType ? catType :'service') ,
    };

    return initialValues;
};

export const AddCategoryForm = ({catType,onCancel, onAddCategory, editableData}) => {
    const companyId = useCompanyData();
    const validationSchema = Yup.object().shape({
        category_name: Yup.string().max(255).required('Category Name'),
    });

    const formik = useFormik({
        initialValues: getInitialValues(catType, editableData),
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            const body = {
                title: values.category_name,
                company: companyId,
                status: values.categoryEnableForAppointment ? "active" : "inactive",
                type: values?.type,
              };
              onAddCategory(body)
        }
           
    });
    
  const { handleSubmit, handleChange, values, handleBlur, errors, touched, getFieldProps , isSubmitting} =
  formik;
  return (
    <FormikProvider value={formik}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {editableData
                ? <FormattedMessage id="service.section.catelogue.update-category" defaultMessage="Update Catgeory" />
                : <FormattedMessage id="service.section.catelogue.add-category" defaultMessage="Add Category"/>
              }
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 3 }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Category Name"
                            {...getFieldProps('category_name')}
                            error={Boolean(touched.category_name && errors.category_name)}
                            // helperText={touched.category_name && errors.category_name}
                            value={values?.category_name}
                        />
                    </Grid>
                  
                    <Grid item xs={12}>
                        <Select
                            fullWidth
                            name="type"
                            value={values?.type}
                            onChange={handleChange}
                            // {...getFieldProps('client')}
                            placeholder="Select Values"
                            >
                            {productServiceTypeOptions?.map((item, index) => {
                                return (
                                <MenuItem key={uuid()} value={item?.value}>
                                    {item?.label}
                                </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                    {values.type=='service' && (
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={values?.categoryEnableForAppointment} {...getFieldProps('categoryEnableForAppointment')}  onChange={handleChange}/>}
                                    label={<FormattedMessage id="enable-for-appointment" defaultMessage="Enable for appointment" />}
                                />
                            </Grid>
                    )}

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="flex-end">
                            <Button type="button" variant="outlined" onClick={onCancel}>
                                <FormattedMessage id="service.button.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button type="submit" variant="contained" disabled={isSubmitting}>
                                {editableData ? <FormattedMessage id="service.button.catelogue.update-category" defaultMessage="Update" /> :<FormattedMessage id="service.button.catelogue.add-category" defaultMessage="Add" />}
                            </Button>
                        </Stack>
                    </Grid>
                   
                </Grid>
            </DialogContent>
        </Form>
    </LocalizationProvider>
</FormikProvider>
  )
}

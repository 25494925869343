// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/snackbar/slice';
import { ENDPOINTS } from 'network/ENDPOINT';
import { fetchUserData } from 'store/users/action';
import { fetchCountryList } from 'store/meta/action';
import { fetchMultiStoreList } from 'store/multi-store/action';
import { authenticate, setCountData, setShowOTPModal } from 'store/auth/slice';
import { getCategory } from 'store/catalogue/slice';
import { getStaffs } from 'store/employees/slice';
import { getBussinessSetUpData } from 'utils/general';

// ----------------------------------------------------------------------

const initialState = {
    has_error: false,
    is_ready_for_otp_validation:false,
    ownerData:false,
    token:false,
    currentOutletData:false,
    businessSetupData:null,
    businessSetupTime:null,
};

const slice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.has_error = action.payload;
        },
       
        loginSuccess(state , action){
            state.is_ready_for_otp_validation=true
            state.ownerData = action.payload;
            state.token = action.payload?.token ?? null;
            state.currentOutletData = action?.payload?.ownerBusiness?.businessLocations?.find((i)=>{return i?.isPrimaryCompany === true}) || null;
        },

        getBusinessDataList(state, action){
            state.businessSetupData = action.payload;
            state.businessSetupTime = getBussinessSetUpData(action.payload);
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function register(body) {
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.SIGNUP_API, body);
            dispatch(slice.actions.hasError(false));
            dispatch(openSnackbar ({
                open: true,
                message: 'Your registration has been successfully completed.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message:   error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}
export function login(body) {
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.LOGIN, body);
            const result = response?.data?.data;
            console.log("rsult" , result ,"response" ,response);
            const ownerId = result?.ownerBusiness?.ownerId;
            const companyId = result?.ownerBusiness?.businessLocations?.find((i)=> {return i?.isPrimaryCompany == true})?.id;
            const countData = result?.companyData;
            
            dispatch(slice.actions.loginSuccess(result));
            dispatch(fetchUserData({ companyId }));
            dispatch(getCategory(companyId));
            dispatch(getStaffs(companyId));
            dispatch(fetchCountryList());
            dispatch(getBusinessPreferenceList(companyId));
            dispatch(fetchMultiStoreList(ownerId));
            dispatch(
                setCountData ({
                employeeCount: countData?.employeeCount,
                serviceCount: countData?.serviceCount,
                appointmentCount: countData?.appointmentCount,
                bussinessSetUpDataCount: countData?.bussinessSetUpDataCount,
                })
            );
            dispatch(authenticate(true));

            dispatch(slice.actions.hasError(false));



        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message:  error.error || error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function resetPassword(body){
    return async () => {
        try {
            const response = await axios.patch(ENDPOINTS.FORGOT_PASSWORD_API, body);
            dispatch(slice.actions.hasError(false));
            dispatch(slice.actions.isReadyForOTPValidation (true));
           
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function getBusinessPreferenceList(companyId){
    return async () => {
        try {
            const response = await axios.get(`${ENDPOINTS.GET_BUSSINESS_SETUP_API}?companyId=${companyId}`);
            dispatch(slice.actions.hasError(false));
            dispatch(slice.actions.getBusinessDataList(response.data.data))
            // dispatch(slice.actions.isReadyForOTPValidation (true));
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}


export function setUpBussinessAPI(body){
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.SET_UP_BUSSINESS_API, body);
            dispatch(slice.actions.hasError(false));
            // dispatch(slice.actions.isReadyForOTPValidation (true));
            dispatch(openSnackbar({
                open: true,
                message:  "Updated Bussiness Data",
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
           
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function initializeOTPValidation(){
    return async () => {
        dispatch(slice.actions.isReadyForOTPValidation(false));
    }
}

export function updatePasswordAPI({body, companyId}) {
    return async () => {
        try {
            const response = await axios.patch(`${ENDPOINTS.UPDATE_PASSWORD_API}/${companyId}`, body);
            dispatch(openSnackbar({
                open: true,
                message: 'Your Password has been Updated Successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            dispatch(slice.actions.hasError(false));
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.error || error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))

        }
    };
}

import { useTheme } from "@emotion/react";
import { Box, Button, Chip, Collapse, Dialog, DialogContent, DialogContentText, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useCompanyData } from "components/hooks/useCompanyData";
import React, { useEffect } from "react";
import { dispatch, useAppSelector } from "store";
import { addService, deleteService, initializeExpandCategory, updateService } from "store/catalogue/slice";
import Loader from "ui-component/Loader";
import { capitalize, formatDuration } from 'utils/general';

//third-party
import { v4 as uuid } from 'uuid'

//assets
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SubCard from "ui-component/cards/SubCard";
import { ThemeMode } from "config";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import ClearIcon from '@mui/icons-material/Clear';
import { AddForm } from "./forms/AddForm";
import PropTypes from 'prop-types';
import BpCheckbox from "ui-component/extended/Form/Checkbox";
import UserList from "./services/UserList";
import { alpha } from '@mui/material/styles';
import AssignStaffsFormDialog from "./services/AssignedStaffsForm";
import AlertServiceDelete from "pages/users/employees/AlertServiceUnassign";
import CatalogueSkeleton from "ui-component/cards/Skeleton/Catalogue";

export const  Row=({ row, onClickEditData, onDeleteData })=> {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [openServiceModal, setOpenServiceModal] = React.useState(false);
    const [editService, setEditService] = React.useState(null);
    const [deleteConfirm, setDeleteConfirm] = React.useState(null);
    const [assignConfirm, setAssignConfirm] = React.useState(false);
    const companyId = useCompanyData();
    const { serviceStaffRelationList } = useAppSelector ((state) => state.staff);
    const serviceState = useAppSelector((state) => state.catalogue);
    const [selected, setSelected] = React.useState([]);
    const cateType = row.cateType==='service' ? 'Service' :"Product"

    const customChip = {
        inactive:{label : 'Inactive',
            color : 'orange.dark',
            chipcolor : alpha(theme.palette.orange.light, 0.8)
        },
        active:{label : 'Active',
            color : 'success.dark',
            chipcolor : alpha(theme.palette.success.light, 0.6)
        }
    }

    const handleCreateService = async (data) => {
        setLoading(true);
        try {
            if (editService) {
                dispatch (
                    updateService({
                        body: data,
                        id: editService?.id,
                        categoryType:cateType
                    })
                ).then(() => setLoading(false), setOpenServiceModal(false), setEditService(null));
            } else {
                dispatch(addService({body:data,categoryType:cateType})).then(() => setLoading(false), setOpenServiceModal(false));
            }

            // if (!serviceState.error) setOpenServiceModal(false);
        } catch (err) {
            console.log("err", err);
        }
    }

    const handleClickDelete = (data) => {
        setLoading(true);
        dispatch(deleteService({ catalogueId: data?.catalogueId, id: data?.id, categoryType:cateType })).then(() => {
            if (!serviceState.error) setDeleteConfirm(null);
            setLoading(false);
        });
    }


    const handleCheckBox = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            if (selected.length > 0) {
                setSelected([]);
            } else {
                const newSelectedId = row.svcCtlgItems.map((n) => n.id);
                setSelected(newSelectedId);
            }
            return;
        }
        setSelected([]);
    };

    const isSelected = (serviceId) => {
        return selected.indexOf(serviceId) !== -1;
    }

    const handleAssignConfirmDialog = () => {
        setAssignConfirm(!assignConfirm);
    }

    // const handleAssignServices = () => {
    //     setLoading(true);
    //     if (selected?.length > 0) {
    //         const data = {
    //             empCtgyItem: selected,
    //             catalogueId: row.id
    //         };
    //         dispatch(
    //             asignServiceToStaffApi ({
    //                 body: data,
    //                 id: user?.id,
    //                 companyId,
    //             })
    //         )
    //     }
    // }

    const handleClickOpen = () => {
        setOpen(!open);
        initializeExpandCategory()
    }



    // if (loading) return <Loader />;
    return (
        <>
           {serviceState.isFetching && ( <CatalogueSkeleton /> )}
           {loading && <Loader />}
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' , cursor:'pointer' } }} selected={open} onClick={handleClickOpen}>
                <TableCell sx={{ pl: 3, minWidth: 120, width: 120, height: 46 }}>
                    <Stack direction="row" spacing={0.25} alignItems="center">
                      
                            <Tooltip title={`Add ${cateType}`}>
                                <IconButton aria-label="expand row" size="small" onClick={(e) => {e.stopPropagation(); setEditService(false);setOpenServiceModal(true)}} >
                                    <AddCircleTwoToneIcon fontSize="small" color="primary" />
                                </IconButton>
                            </Tooltip>
                        <IconButton aria-label="expand row" size="small" onClick={handleClickOpen} >
                            {open ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                        </IconButton>
                    </Stack>
                </TableCell>
                {/* <TableCell sx={{ pl: 3, width: 74 }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell> */}
                <TableCell component="th" scope="row">
                    {capitalize (row.title)} &nbsp; ({row.svcCtlgItems?.length})
                </TableCell>
                <TableCell >
                    <Chip
                        label={customChip[(row?.status).toLowerCase()].label}
                        size="small"
                        sx={{
                            bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : customChip[(row?.status).toLowerCase()].chipcolor,
                            color: customChip[(row?.status).toLowerCase()].color,
                            cursor: 'pointer'
                        }}
                    />
                </TableCell>
                <TableCell align="right" sx={{ pr: 3 }}>
                    {/* <IoMdAddCircle  fontSize={24} color='gray' cursor='pointer' onClick={()=>setOpenServiceModal(true)}/> */}
                    {/* <MdEditDocument fontSize={24} color='gray' cursor='pointer' onClick={()=>{onClickEditData(row)}}/> */}
                        <>
                            <Tooltip title="Edit Category">
                                <IconButton
                                    color="secondary"
                                    size="small"
                                    aria-label="Edit Category"
                                    onClick={() => { onClickEditData(row) }}
                                >
                                    <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Category">
                                <IconButton color="error" size="small" aria-label="Delete" onClick={() => { onDeleteData(row) }}>
                                    <DeleteTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                </IconButton>
                            </Tooltip>
                        </>
                    {/* <MdDelete fontSize={24} color='red' cursor='pointer' onClick={()=>{onDeleteData(row)}}/> */}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open  || serviceState.expandedCategory === row.id} timeout="auto" unmountOnExit>
                        {(open || serviceState.expandedCategory === row.id) && (
                            <Box  sx={{ margin: 1 }}
                            >
                                <SubCard
                                    sx={{ bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.800' : 'grey.50', mb: 2 }}
                                    title=""
                                    content={false}

                                >
                                    {selected.length > 0 && (
                                        <EnhancedTableToolbar  selected={selected} items={row} handleAssignConfirmDialog={handleAssignConfirmDialog} />
                                    )}
                                    <TableContainer>
                                        <Table size="small" aria-label="purchases">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell >
                                                        <BpCheckbox
                                                            color="primary"
                                                            indeterminate={selected.length > 0 && selected.length < row?.svcCtlgItems?.length}
                                                            checked={row?.svcCtlgItems?.length > 0 && selected.length === row?.svcCtlgItems?.length}
                                                            onChange={handleSelectAllClick}
                                                            inputProps={{
                                                                'aria-label': 'select all desserts'
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{cateType} Name</TableCell>
                                                    {row.cateType=='service' && (<TableCell>Time</TableCell>)}
                                                    <TableCell>Price (₹)</TableCell>
                                                    {row.cateType=='service' && ( <TableCell align="right">Status</TableCell>)}
                                                    <TableCell align="right" />

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.svcCtlgItems?.map((historyRow) => (
                                                    <TableRow hover key={uuid()}>
                                                        <TableCell sx={{ width: '1.0rem' }} onClick={(event) => handleCheckBox(event, historyRow.id)}>
                                                            <BpCheckbox checked={isSelected(historyRow.id)} />
                                                        </TableCell>
                                                        {/* defaultChecked={serviceStaffRelationList?.find(sr=>sr.svcCtlgItemsId==historyRow.id)} */}
                                                        <TableCell>{capitalize (historyRow.title)}</TableCell>
                                                       {row.cateType=='service' && ( <TableCell> {formatDuration(historyRow?.duration)}</TableCell> )}
                                                       <TableCell>{historyRow.price}</TableCell>
                                                       {row.cateType=='service' && ( <TableCell align="right"> 
                                                            <Chip
                                                                label={customChip[(historyRow?.status).toLowerCase()].label}
                                                                size="small"
                                                                sx={{
                                                                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : customChip[(historyRow?.status).toLowerCase()].chipcolor,
                                                                    color: customChip[(historyRow?.status).toLowerCase()].color,
                                                                    cursor: 'pointer'
                                                                }}
                                                            /> 
                                                         </TableCell> )}
                                                       
                                                        <TableCell align="right">
                                                            <div>
                                                                    <>
                                                                       
                                                                            <IconButton
                                                                                color="secondary"
                                                                                size="small"
                                                                                aria-label="Assigned Staffs"
                                                                                //onClick={() => { setOpenServiceModal(true); setEditService(historyRow) }}
                                                                            >
                                                                                <UserList  service={historyRow} cateType={row.cateType} handleAssignConfirmDialog={handleAssignConfirmDialog} setSelected={setSelected}  />
                                                                                {/* <GroupIcon sx={{ fontSize: '1.0rem' }} /> */}
                                                                            </IconButton>
                                                                       

                                                                        <Tooltip title={`Edit ${cateType}`}>
                                                                            <IconButton
                                                                                color="secondary"
                                                                                size="small"
                                                                                aria-label="Edit Service"
                                                                                onClick={() => { setOpenServiceModal(true); setEditService(historyRow) }}
                                                                            >
                                                                                <EditTwoToneIcon sx={{ fontSize: '1.0rem' }} />
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <Tooltip title={`Delete ${cateType}`}>
                                                                            <IconButton color="error" size="small" aria-label="Delete Service" onClick={() => setDeleteConfirm(cateType === 'Service' ? {...historyRow, catalogueId:row?.id} : historyRow)}>
                                                                                <DeleteTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                {/* <MdEditDocument fontSize={20} color='gray' cursor='pointer'  onClick={()=>{setOpenServiceModal(true);setEditService(historyRow)}}/> */}
                                                                {/* <MdDelete fontSize={20} color='red' cursor='pointer' onClick={()=>setDeleteConfirm(historyRow)}/> */}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </SubCard>
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>

            {
                openServiceModal &&
                <Dialog maxWidth="sm" fullWidth onClose={() => setOpenServiceModal(false)} open={openServiceModal} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                    <AddForm catalogue={row} onCancel={() => { setOpenServiceModal(false); setEditService(null) }} onAddService={handleCreateService} editableService={editService} />
                </Dialog>
            }
            
            {deleteConfirm && 
                <AlertServiceDelete 
                    title="The selected item will be deleted."  {...(cateType === 'Service' && {
                    subTitle: "Please note that by removing this service, the assigned staff will no longer be able to schedule appointments related to it.",
                    })}
                    open={deleteConfirm ? true:false} 
                    handleClose={()=>setDeleteConfirm(null)} 
                    item={deleteConfirm} 
                    handleSubmit={handleClickDelete} 
                />}



            {/* {deleteConfirm &&
                <Dialog maxWidth="sm" onClose={() => setDeleteConfirm(null)} open={deleteConfirm} sx={{ '& .MuiDialog-paper': { p: 4 } }}>
                    Are tou sure you want to delete service ?
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="flex-end">
                            <Button type="button" variant="outlined" onClick={() => { setDeleteConfirm(null) }}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" disabled={false} onClick={() => { handleClickDelete(deleteConfirm) }}>
                                Delete
                            </Button>
                        </Stack>
                    </Grid>
                </Dialog>
            } */}


            {assignConfirm &&
                <AssignStaffsFormDialog
                    open={assignConfirm}
                    handleClose={handleAssignConfirmDialog}
                    assigned_services={selected}
                    categoryId={row.id}
                    //handleOnChange={handleOnStaffChange} 
                    //handleOnDelete={handleOnDelete} 
                />

                // <Dialog
                //     maxWidth="md"
                //     onClose={() => setAssignConfirm(false)}
                //     open={assignConfirm}
                //     sx={{ '& .MuiDialog-paper': { p: 4 } }}
                // >
                //     {/* <DialogTitle>The following service(s) are assigned to the selected user(s)</DialogTitle> */}
                //     <Typography variant='h5'>The following item(s) will be assigned to the selected staff(s).</Typography>
                //     {/* <AssignServices1Form
                //         services={selected}
                //         row={row}
                //         setAssignConfirm={setAssignConfirm}
                //         user={user}
                //     /> */}
                // </Dialog>
            }
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};
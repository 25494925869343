
export const minOptions = [
  { label: "00", value: "00" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
];

export const categoryType = [
  {label:"Service", value:"service"},
  {label:"Product", value:"product"},
]


export const CONST_categoryType = {
    SERVICE:'service',
    PRODUCT:'product'
}
import { HowToReg } from "@mui/icons-material";
import { alpha, Button, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PropTypes from 'prop-types';
export const EnhancedTableToolbar = ({ selected, handleAssignConfirmDialog , items }) => {
    const numSelected = selected.length;
    return (
        <Toolbar
            variant='dense'
            sx={{
                minHeight: 35,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha (theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <>
                {items.cateType==='service'  && (
                    <Tooltip title="Assign Service(s)">
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={handleAssignConfirmDialog}
                            endIcon={<HowToReg />}
                        >
                            Assign
                        </Button>
                    </Tooltip>
                    )}
                {/* {!user && (
                    <Tooltip title="Delete Service(s)" sx={{marginLeft:1}}>
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            //onClick={handleDeleteConfirmDialog}
                            endIcon={<DeleteTwoToneIcon sx={{ fontSize: '1.3rem' }} />}
                        >
                            Delete
                        </Button>
                    </Tooltip>
                )} */}
                </>
            ) : (
                <>
                </>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    selected: PropTypes.array.isRequired,
    items: PropTypes.object.isRequired,
    handleAssignConfirmDialog: PropTypes.func.isRequired
};  
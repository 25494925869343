import dashboard from './dashboard';
import application from './application';
import appointimize from './appointimize';
import forms from './forms';
import elements from './elements';
import samplePage from './sample-page';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [appointimize]
};

export default menuItems;

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { dispatch, useAppSelector } from 'store';
import { Autocomplete, capitalize, darken, IconButton, lighten, List, ListItem, ListItemIcon, ListItemText, Stack, styled, Tooltip } from '@mui/material';
import { useCountyPrice } from 'components/hooks/useCountyPrice';
import Items from 'pages/users/employees/Items';
import { useCompanyData } from 'components/hooks/useCompanyData';
import { v4 as uuid } from 'uuid'
import { assignEmployeeToMultipleServices } from 'store/catalogue/slice';
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});





 const StaffList = ({ handleOnChange ,selected , categoryId}) => {
    const [staffs, setStaffs] = React.useState([]);
    const { serviceEmployees  } = useAppSelector((state) => state.catalogue);
    const employees = useAppSelector((state) => state.employees.employeeList) || [];
    const { format } = useCountyPrice();
    const companyId = useCompanyData();
    React.useState(() => {
        let options = [];  
        const assigned_employees= selected.flatMap(services => services.employeeRelation!==undefined ? services.employeeRelation :[] );
        //console.log("assigned_employees" , assigned_employees ,"selected" , selected) 
          employees.map((e) => {
                if(assigned_employees && Array.isArray(assigned_employees) && !assigned_employees.some((a)=>a.employeeId==e.id)){
                    options.push({ label: `${e?.firstName} ${e?.lastName}`,eid:e.employeeId, ...e , key:uuid() });
                }
          });
      
        setStaffs(options);
    }, [])


    const handleOnStaffChange = (e, v)=>{
        if(v){
            const data = {
                empCtgyItem: Array.isArray(selected) ? selected.map(s=>s.id) : [selected.id],
                catalogueId: categoryId,
            };
            dispatch (
                assignEmployeeToMultipleServices ({
                    body: data,
                    id: v.id,
                   
                })
            )
        }   
       
    }


    return (
        <Stack spacing={2} sx={{ width: 540 }}>
        <Autocomplete
            className='staff-autocomplete'
            freeSolo
            options={staffs}
            getOptionLabel={(option) => {
                    if(!option) return ""
                    return option?.label
            }}
            renderInput={(params) => <TextField {...params} value={''} placeholder="Search by staff name or phone number" />}
            onChange={handleOnStaffChange}

        />

        </Stack>
    )
}


const findService = (categories , services) => {
    // Flatten all services from all categories into a single array
    const allServices = categories.flatMap(category => category.svcCtlgItems);
    // Find the product that matches any in the inputArray
  return allServices.filter(as => 
    services.some(service =>
        service === as.id
    )
  );
}

export default function AssignStaffsFormDialog({ open, handleClose, assigned_services, handleOnChange, handleOnDelete ,categoryId }) {
    const [isUnassignAlertopen,setIsUnassignAlertopen] = React.useState(false);
    const serviceState = useAppSelector((state) => state.catalogue);
    const handleAlertOpen = ()=>{
        setIsUnassignAlertopen(true);
    }
    const handleAlertClose = ()=>{
        setIsUnassignAlertopen(false);
    }

    const filtered_Services = findService(serviceState.serviceList , assigned_services)
      
    return (
        <React.Fragment>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Assign Staff(s)</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <StaffList handleOnChange={handleOnChange} selected={filtered_Services} categoryId={categoryId} />
                    </DialogContentText>
                    <List dense={true} sx={{ 
                        pt: 5 
                    }}>
                        {

                        filtered_Services?.map((service , i) => {
                                return (
                                    <Items isDeletedRequired={false} assigned_services={assigned_services} service={service} index={i} handleOnDelete={handleOnDelete} />

                                )
                            })
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
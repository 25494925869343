import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import GroupIcon from '@mui/icons-material/Group';
import { dispatch, useAppSelector } from 'store';
import { v4 as uuid } from 'uuid'
import { useCompanyData } from 'components/hooks/useCompanyData';

export default function UserList({service , cateType ,handleAssignConfirmDialog ,setSelected}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [staffs , setStaffs] = React.useState([])
  const {serviceEmployees}  = useAppSelector(state=>state.catalogue)
  const companyId = useCompanyData()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    const assignedStaffs = serviceEmployees.get(service.id)?.flatMap(e=>e.employee) || [];//service && service?.employeeRelation ? service?.employeeRelation?.map(e=>e.employee) : [];
    setStaffs(assignedStaffs);
    setAnchorEl(event.currentTarget);
  };

//   React.useEffect(()=>{
   
//     setUsers(staffServiceState.serviceStaffRelationList)
//   },[staffServiceState])
  const handleClose = () => {
    setAnchorEl(null);
  };
  if(cateType!='service'){
    return (
        <></>
    )
  }
  return (

    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
           <GroupIcon  onClick={handleClick}  />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        { staffs.map(s=>(
              <MenuItem onClick={handleClose} key={uuid()}>
                  <Avatar /> {s?.firstName} {s?.lastName}
              </MenuItem>
        ))}
       { staffs.length > 0 ? <Divider /> :``}
        <MenuItem onClick={()=>{
          setSelected([service.id])
          handleAssignConfirmDialog();
          handleClose()
        }} >
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
         { staffs.length > 0 ? `Add another staff` : `Add Staff` }
        </MenuItem>
 
      </Menu>
    </React.Fragment>
  );
}

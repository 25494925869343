import PropTypes from 'prop-types';

// material-ui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { prototype } from 'events';

// ==============================|| Service Unassign  ||============================== //

export default function AlertServiceDelete({ title,subTitle, open, handleClose ,item  ,handleSubmit}) {
    const handleOnSubmit = () =>{
        handleSubmit(item);
        handleClose(false);
    }
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            maxWidth="xs"
            aria-labelledby="column-delete-title"
            aria-describedby="column-delete-description"
        >
            {open && (
                <>
                    <DialogTitle id="column-delete-title">{title} - Are you sure you want to remove?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="column-delete-description">
                             {subTitle}  
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <Button onClick={() => handleClose(false)} color="error">
                            Cancel
                        </Button>
                        <Button variant="contained" size="small" onClick={handleOnSubmit} autoFocus>
                            Remove
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

AlertServiceDelete.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    subTitle:PropTypes.string,
    item: PropTypes.object,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
};

import useAuth from 'components/hooks/useAuth';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
//import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
   // const { isLoggedIn } = useAuth();
   const auth = useAuth ();
    //console.log("isLoggedIn" , isLoggedIn)
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth) {
            navigate('login', { replace: true });
        }
    }, [auth, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;

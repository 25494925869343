import { Avatar, AvatarGroup,  IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material"
import { v4 as uuid } from 'uuid'
import { IconBasketCheck, IconPlus } from '@tabler/icons-react';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AlertServiceDelete from './AlertServiceUnassign';
import { useState } from "react";
import { useAppSelector } from "store";
import { capitalize } from "utils/general";

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 24,
        height: 24,
        fontSize: 15,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
function EmployeeGroupedAvatars({service}) {
    const {serviceEmployees } = useAppSelector ((state) => state.catalogue);
    const assigned_employees = serviceEmployees.get(service.id);
    return (
        <AvatarGroup total={assigned_employees?.length}>
       { assigned_employees && assigned_employees.length > 0 && assigned_employees.map(e=>(
         <Tooltip title={`${e?.employee?.firstName} ${e?.employee?.lastName}`}>
            <Avatar  {...stringAvatar(`${e?.employee?.firstName.trim()} ${e?.employee?.lastName.trim()}`)}  />
         </Tooltip>
          
        ))
        }
        </AvatarGroup>
     
    );
  }



const Items = ({assigned_services , service , handleOnDelete ,index ,isDeletedRequired=true}) => {
    const [isUnassignAlertopen,setIsUnassignAlertopen] = useState (false);
    const {isFetching } = useAppSelector ((state) => state.employees);
    const handleAlertOpen = ()=>{
        setIsUnassignAlertopen(true);
    }
    const handleAlertClose = ()=>{
        setIsUnassignAlertopen(false);
    }
    const name = capitalize (service.title);
    return (
        <>
            <ListItem
               
                key={uuid()}
                secondaryAction={
                  isDeletedRequired ? (
                    <Tooltip title={`Remove ${name} from the list`}>
                        <IconButton color="error" size="small" aria-label="Delete" onClick={handleAlertOpen} >
                            <DeleteTwoToneIcon sx={{ fontSize: '1.4rem' }} />
                        </IconButton>
                    </Tooltip>
                  ): ''
                }
            >
                
                <ListItemIcon >
                    <IconBasketCheck stroke={1.5} size="24px" color="blue" />
                </ListItemIcon>
               
                <ListItemText
                    primary={name}
                    
                />
                 <EmployeeGroupedAvatars service={service} />
                  
            </ListItem>
            {isUnassignAlertopen && <AlertServiceDelete title={service.title} subTitle="If you delete, their information will be permanently removed.
                    To retain the information, you can change their status to 'Inactive' instead." open={isUnassignAlertopen} handleClose={handleAlertClose} item={service} handleSubmit={handleOnDelete} />}
        </>
    )
}

export default Items;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ENDPOINTS } from "../../network/ENDPOINT";
import { patchAPI, postAPI } from "../../network/Api";
import { BusinessLocation, Users } from "../../utils/types/responseType";
import {
  RequestChangePassword,
  forgotPasswordRequest,
  loginRequest,
  resetPasswordRequest,
  signupRequest,
  verifyOtpRequest,
} from "../../utils/types/requestType";
import {
  authenticate,
  resetProfile,
  setCountData,
  setShowOTPModal,
} from "./slice";
import { apiSignature } from "./api-signature";
import { setUpdatePassword } from "../meta/slice";
import { fetchBussinessPreferenceList, fetchCountryList } from "../meta/action";
import { fetchUserData } from "../users/action";
import { checkMessage } from "../../utils/general";
import { NavigateFunction } from "react-router-dom";
import { PublicPath } from "../../components/constants/routes.c";
import { fetchMultiStoreList } from "../multi-store/action";
import { getCategory } from "store/catalogue/slice";
import { getStaffs } from "store/employees/slice";

export const loginAPI = createAsyncThunk<Users, loginRequest>(
  apiSignature.AUTH_LOGIN,
  async (values: loginRequest, thunkAPI) => {

    console.log("Hello Login" ,values)
    const { dispatch } = thunkAPI;
    try {
      const response = await postAPI(ENDPOINTS.LOGIN, values);
      if (response?.success) {
        const result = response?.data;
        const ownerId = result?.ownerBusiness?.ownerId;
        const companyId = result?.ownerBusiness?.businessLocations?.find((i:BusinessLocation)=> {return i?.isPrimaryCompany == true})?.id;
        const countData = result?.companyData;
          dispatch(fetchUserData({ companyId }));
          dispatch(getCategory(companyId));
          dispatch(getStaffs(companyId));
          dispatch(fetchCountryList());
          dispatch(fetchBussinessPreferenceList(companyId));
          dispatch(fetchMultiStoreList(ownerId));
          dispatch(
            setCountData({
              employeeCount: countData?.employeeCount,
              serviceCount: countData?.serviceCount,
              appointmentCount: countData?.appointmentCount,
              bussinessSetUpDataCount: countData?.bussinessSetUpDataCount,
            })
          );
          dispatch(authenticate(true));
      // dispatch(setMultiStoreList(result?.ownerBusiness));
        return result;
      } else {
        throw response;
      }
    } catch (error) {
      checkMessage(error);
    }
  }
);

export const fetchSignupAPI = createAsyncThunk<
  Users,
  { body: signupRequest; navigate: NavigateFunction }
>(apiSignature.AUTH_REGISTER, async (props, thunkAPI) => {
  const { body, navigate } = props;
  const { dispatch } = thunkAPI;
  try {
    const response = await postAPI(ENDPOINTS.SIGNUP_API, body);
    if (response?.success) {
      const result = response?.data;
      navigate(PublicPath.login);
      // dispatch(setShowOTPModal(true));
      return result;
    }
  } catch (error) {
    checkMessage(error);
    console.log("error", error);
  }
});

export const emailVerification = createAsyncThunk<Users, any>(
  apiSignature.AUTH_EMAIL_VERIFY,
  async (props: any, thunkAPI) => {
    const { value, id, type } = props;
    const { dispatch } = thunkAPI;
    try {
      const response = await patchAPI(
        `${ENDPOINTS.EMAIL_VERIFICATION}/${id}`,
        value
      );
      if (response?.success) {
        // dispatch(setShowOTPModal(false));
        const result = response?.data;
        // if (result?.role == "client") {
        //   dispatch(setSelectedClient(result));
        // }
        return result;
      }
    } catch (error) {
      checkMessage(error);
      console.log("error", error);
    }
  }
);

export const updatePasswordAPI = createAsyncThunk<
  void,
  { values: RequestChangePassword; id: string }
>(apiSignature.UPDATE_PASSWORD, async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { values, id } = payload;

  try {
    const response = await patchAPI(
      `${ENDPOINTS.UPDATE_PASSWORD_API}/${id}`,
      values
    );
    if (response?.success) {
      const result = response?.data;
      dispatch(setUpdatePassword(false));
      return result;
    }
  } catch (error) {
    checkMessage(error);
    console.log("error", error);
  }
});

export const forgotPasswordAPI = createAsyncThunk<Users, forgotPasswordRequest>(
  apiSignature.AUTH_FORGOT_PASSWORD,
  async (values, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response = await patchAPI(ENDPOINTS.FORGOT_PASSWORD_API, values);
      if (response?.success) {
        const result = response?.data;
        dispatch(setShowOTPModal(true));
        return result;
      } else {
        throw response;
      }
    } catch (error) {
      checkMessage(error);
    }
  }
);

export const resetPasswordAPI = createAsyncThunk<
  Users,
  { body: resetPasswordRequest; navigate: NavigateFunction }
>(apiSignature.AUTH_RESET_PASSWORD, async ({ body, navigate }, thunkAPI) => {
  const { dispatch } = thunkAPI;
  try {
    const response = await patchAPI(ENDPOINTS.RESET_PASSWORD_API, body);
    if (response?.success) {
      const result = response?.data;
      navigate(PublicPath?.login);
      return result;
    } else {
      throw response;
    }
  } catch (error) {
    checkMessage(error);
  }
});

export const verifyOTP = createAsyncThunk<
  Users,
  { body: verifyOtpRequest; navigate: NavigateFunction }
>(apiSignature.AUTH_VERIFY_OTP, async ({ body, navigate }, thunkAPI) => {
  const { dispatch } = thunkAPI;
  try {
    const response = await patchAPI(ENDPOINTS.VERIFY_OTP, body);
    if (response?.success) {
      const result = response?.data;
      navigate(`${PublicPath?.resetPassword}?email=${result?.email}`);
      dispatch(setShowOTPModal(false))
      return result;
    } else {
      throw response;
    }
  } catch (error) {
    checkMessage(error);
  }
});

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    // Perform any async operations here (e.g., log out user from server)
    // For now, just dispatch the logout action synchronously
    dispatch(resetProfile());
  }
);

import { createSlice } from "@reduxjs/toolkit";
import { addCustomer, fetchUserData, isUserVerify } from "./action";
import { Users } from "../../utils/types/responseType";
import { customerRegister } from "../../utils/types/requestType";

interface userSliceProps {
  addCustomerDetails: customerRegister | null;

  addCustomerLoading: boolean;

  recentlyAddedCustomerData: Users | null;
  customerUserLoading: boolean;
  customerUserData: Users[];

  customerCount: number;
}

const initialState: userSliceProps = {
  addCustomerDetails: null,
  addCustomerLoading: false,

  recentlyAddedCustomerData: null,

  customerUserLoading: false,

  // client user
  customerUserData: [],
  customerCount: 0,
};

// createSlice
export const usersSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAddCustomerDetail: (state, action) => {
      state.addCustomerDetails = action.payload;
    },
    setCustomerCount: (state, action) => {
      state.customerCount = action.payload;
    },
    setCustomerClear: (state, action) => {
      state.customerUserData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // add customer data
    builder.addCase(addCustomer.pending, (state) => {
      state.addCustomerLoading = true;
    });
    builder.addCase(addCustomer.fulfilled, (state, action) => {
      if (action.payload) {
        state.customerUserData = [...state?.customerUserData, action.payload];
        state.recentlyAddedCustomerData = action.payload;
      }
      state.addCustomerLoading = false;
    });
    builder.addCase(addCustomer.rejected, (state) => {
      // state.auth = false;
      state.addCustomerLoading = false;
    });

    // fetch client data
    builder.addCase(fetchUserData.pending, (state) => {
      state.customerUserLoading = true;
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.customerUserData = action.payload?.users ?? [];
      state.customerUserLoading = false;
    });
    builder.addCase(fetchUserData.rejected, (state) => {
      state.customerUserLoading = false;
    });

    // isUserVerify client data
    builder.addCase(isUserVerify.pending, (state) => {
      state.customerUserLoading = true;
    });
    builder.addCase(isUserVerify.fulfilled, (state, action) => {
      state.customerUserLoading = false;
    });
    builder.addCase(isUserVerify.rejected, (state) => {
      state.customerUserLoading = false;
    });
  },
});

export const { setAddCustomerDetail, setCustomerCount ,setCustomerClear } = usersSlice.actions;
export default usersSlice.reducer;

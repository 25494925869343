import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
// project import
import Loadable from 'ui-component/Loadable';
// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';
//import AuthenticationRoutes from './AuthenticationRoutes';

//const LoginRoutes = {}
const AuthenticationRoutes = {}


const PagesLanding = Loadable(lazy(() => import('pages/widget/Data')));

// ==============================|| ROUTING RENDER ||============================== //
const router = createBrowserRouter([
   
    AuthenticationRoutes, LoginRoutes, MainRoutes], {
    basename: process.env.VITE_APP_BASE_NAME
});

export default router;

import React from "react";
import {
  Autocomplete,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormattedMessage } from "react-intl";
import { gridSpacing } from "store/constant";
import { useCompanyData } from "components/hooks/useCompanyData";
import { useCountryCurrency } from "components/hooks/useCountryCurrency";
import { minOptions } from "utils/primtive/staticData";
import { regx } from "utils/regx";
import { capitilizeName, convertTimeIntoMinute, convertTimeToMinute } from "utils/general";
import { v4 as uuid } from 'uuid'
import { useSelector } from "react-redux";

const getInitialValues = (event, selectedData, catalogue) => {
  const { hour, minute } = convertTimeIntoMinute(selectedData?.duration);
  const initialValues = {
    service_name: selectedData?.title ?? "",
    hours: selectedData?.duration ? hour == 0 ? "" : hour : "",
    minute: selectedData?.duration
      ? minute == 0
        ? minOptions[0]?.value
        : minute.toString()
      : minOptions[0]?.value,
    price: selectedData?.price ? selectedData?.price : "",
    description: selectedData?.description ? selectedData?.description : "",
    serviceEnableForAppointment:
      selectedData?.status == "inactive" ? false : true,
    category: catalogue?.id ?? "",
    categoryName:catalogue
    // selectedFile: selectedData?.selectImage ?? null,
  };

  return initialValues;
};

export const AddForm = ({ event, onCancel, onAddService, editableService, catalogue }) => {
  const cateType = catalogue.cateType === 'product' ? 'Product' : 'Service'
  const {serviceList,productList } = useSelector((state) => state.catalogue);
  const companyId = useCompanyData();
  const currencySymbol = useCountryCurrency();
  const validationSchema = Yup.object().shape({
    service_name: Yup.string().max(255).required(`${cateType} Name`),
    price: Yup.string().required("Required Price").matches(regx.price, "Invalid  Price"),
    ...(catalogue.cateType==='service' && {
                hours: Yup.string().test('is-valid-hour', "Invalid Time", function (value) {
                      const { minute, hours } = this.parent;
                      if (minute === "00" && hours == undefined) {
                        return false;
                      }
                      if (hours <= 0) {
                        return false;
                      }
                      return true;
                    })
    })
  });
  const formik = useFormik({
    initialValues: getInitialValues(event, editableService, catalogue),
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if(values){
        let body;
        if(cateType === 'Product'){
          body = {
            title: values.service_name,
            price: parseFloat(values.price),
            companyId: companyId,
            catalogueId: values?.category,//values?.category,
            description: values?.description,
          };
        }else{
          body = {
            title: values.service_name,
            price: parseFloat(values.price),
            duration: convertTimeToMinute(values?.hours, values?.minute) ?? null,
            companyId: companyId,
            catalogueId: values?.category,//values?.category,
            description: values?.description,
            status: values?.serviceEnableForAppointment ? "active" : "inactive",
          };
        }
        onAddService(body);
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            {editableService
              ? <FormattedMessage id="service.section.update-service_need_to_change" defaultMessage={`Update ${cateType}`} />
              : <FormattedMessage id="service.section.add-servicee_need_to_change" defaultMessage={`Add ${cateType}`} />
            }
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 3 }}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={cateType === "Product" ? productList : serviceList}
                  value={values?.categoryName}
                  freeSolo
                  name="category"
                  id="free-solo-2-demo"
                  onChange={(e, op) => 
                    { handleChange(e); 
                      setFieldValue('category', op?.id); 
                      setFieldValue('categoryName', op) 
                    }}
                  getOptionLabel={(option) => capitilizeName(option.title)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Please select" />
                  )}
                />

              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`${cateType} Name`}
                  {...getFieldProps("service_name")}
                  error={Boolean(touched.service_name && errors.service_name)}
                  // helperText={touched.service_name && errors.service_name}
                  value={values?.service_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`Price (${currencySymbol})`}
                  {...getFieldProps("price")}
                  error={Boolean(touched.price && errors.price)}
                  // helperText={touched.price && errors.price}
                  value={values?.price}
                />
              </Grid>
              {catalogue.cateType === 'service' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="hours"
                      label="Hours"
                      {...getFieldProps("hours")}
                      error={Boolean(touched.hours && errors.hours)}
                      helperText={touched.hours && errors.hours}
                      value={values?.hours}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel >
                        <FormattedMessage
                          id="minute"
                          defaultMessage="Minute"
                        />
                      </InputLabel>
                        <Select
                          fullWidth
                          name="minute"
                          value={values?.minute}
                          onChange={handleChange}
                          // {...getFieldProps('client')}
                          placeholder="minute"
                          label="minute"
                        >
                          {minOptions?.map((item, index) => {
                            return (
                              <MenuItem key={uuid()} value={item?.value}>
                                {item?.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values?.serviceEnableForAppointment}
                          {...getFieldProps("serviceEnableForAppointment")}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <FormattedMessage
                          id="enable-for-appointment"
                          defaultMessage="Enable for appointment"
                        />
                      }
                    />
                  </Grid>

                </>

              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Notes"
                  multiline
                  rows={3}
                  {...getFieldProps("description")}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Button type="button" variant="outlined" onClick={onCancel}>
                    <FormattedMessage id="service.button.cancel" defaultMessage="Cancel" />
                  </Button>
                  <Button type="submit" variant="contained" disabled={false}>
                    {editableService ? <FormattedMessage id="service.button.update-service" defaultMessage="Add" /> : <FormattedMessage id="service.button.add-service" defaultMessage="Update" />}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
};

import { Suspense } from 'react';

// project imports
import Loader from './Loader';
import CatalogueSkeleton from './cards/Skeleton/Catalogue';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) => (
    // <Suspense fallback={<Loader />}>
    //     <Component {...props} />
    // </Suspense>
    <Suspense fallback={<CatalogueSkeleton />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;

import { useAppSelector } from "../../store";
import { selectAuthData } from "../../store/auth/selector";

export default function useAuth() {
  const auth = useAppSelector(selectAuthData);
  if (auth) {
    return true;
  }
  return false;
}
